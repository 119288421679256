import React from "react";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _set from "lodash.set";
import moment from "moment";
import { isEqual } from "date-fns";
import { AuthTypes, InternPermissions } from "./constants";

export function validateEmail(email) {
  return email
    ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    : true;
}

export function isPositiveIntBelowMax(
  valueString,
  maxNumber,
  allowZero = false,
) {
  const number = Number(valueString);
  return (
    (allowZero ? number >= 0 : number > 0) &&
    number % 1 === 0 &&
    number <= maxNumber
  );
}

export function formatValuesForForm(values, dateFields) {
  return iter(values, dateFields);
}

function iter(obj, dateFields) {
  Object.keys(obj).forEach(function (k) {
    if (obj[k] !== null && typeof obj[k] === "object") {
      if (k === "person" || k === "spouse") {
        Object.keys(obj[k]).forEach(function (i) {
          if (i === "contactMethods") {
            const contactMethods = obj[k][i] || [];
            if (contactMethods.map((cm) => cm.type).indexOf("Email") < 0) {
              contactMethods.push({
                isPrimary: true,
                isPreferred: false,
                type: "Email",
                value: "",
              });
            }
            if (
              contactMethods.map((cm) => cm.type).indexOf("MobileNumber") < 0
            ) {
              contactMethods.push({
                isPrimary: true,
                isPreferred: false,
                type: "MobileNumber",
                value: "",
              });
            }
          } else if (obj[k][i] === null) {
            obj[k][i] = "";
          }
        });

        return;
      }

      if (k === "contactMethods") {
        const contactMethods = obj[k] || [];
        if (contactMethods.map((cm) => cm.type).indexOf("WorkNumber") < 0) {
          contactMethods.push({
            type: "WorkNumber",
            value: "",
          });
        }
      }

      iter(obj[k], dateFields);
      return;
    }

    if (obj[k] === null) {
      obj[k] = "";
    } else if (
      dateFields &&
      dateFields.length &&
      dateFields.indexOf(k) >= 0 &&
      typeof obj[k] === "string" &&
      Date.parse(obj[k])
    ) {
      obj[k] = formatDateForInput(obj[k]);
    }

    if (k === "address" && !obj[k]) {
      obj[k] = {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      };
    }
  });

  return obj;
}

export function formatDateForInput(dateString) {
  let date = "";
  if (dateString) {
    date = dateString;
    if (dateString.toString().length > 10) {
      var d = new Date(new Date(dateString)),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
  }
  return date;
}

export function formatDateTimeForInput(dateString) {
  let date = "";
  if (dateString) {
    date = dateString;
    if (dateString.toString().length > 10) {
      let d = new Date(new Date(dateString)),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.toString().length < 2) hours = "0" + hours;
      if (minutes.toString().length < 2) minutes = "0" + minutes;
      if (seconds.toString().length < 2) seconds = "0" + seconds;

      const dateTime =
        [year, month, day].join("-") +
        "T" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return dateTime;
    }
  }
  return date;
}

export function removeTimezoneFormatFromDate(date) {
  //removing date timezone formatting to avoid server-side timezone conversion
  if (date && date.toString().indexOf("0") === 0) {
    //for manually entered dates - avoid formatting before entry is completed
    return date;
  }

  return date
    ? (moment.isMoment(date) ? date : moment(date)).format(
        "YYYY-MM-DDTHH:mm:ss",
      )
    : null;
}

export function dateAddYears(date, yearsToAdd) {
  return new Date(date.setFullYear(date.getFullYear() + yearsToAdd));
}

export function dateAddDays(date, daysToAdd) {
  return new Date(date.setDate(date.getDate() + daysToAdd));
}

export function isDateEqual(date, dateCompare) {
  return isEqual(new Date(date), new Date(dateCompare));
}

export function isNotValidDOB(date) {
  if (
    new Date(date) < dateAddYears(new Date(), -100) ||
    new Date(date) > new Date()
  )
    return "Invalid Date of Birth";
  return "";
}

export const currencyCodes = {
  CAD: "CAD",
  GBP: "GBP",
  USD: "USD",
};

const currencySymbols = {
  CAD: "CAD $",
  GBP: "£",
  USD: "$",
};

export function getCurrencySymbol(currencyCode) {
  return currencySymbols[currencyCode] || "";
}

export function formatCurrency(num, currencyCode = "") {
  const currency = currencySymbols[currencyCode] || "";

  if (!num) {
    return `${currency}0`;
  }

  num = parseFloat(num).toFixed(2);
  var parts = num.toString().split(".");
  return (
    currency +
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  );
}

export function formatExcelNumberString(numberString) {
  return numberString ? `="${numberString}"` : null;
}

export function formatFullName(firstName, lastName, middleName) {
  return [firstName, middleName, lastName].filter((name) => name).join(" ");
}

export function formatNumber(numString) {
  return numString
    ? numString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0;
}

export function formatAddressDisplay(
  address1,
  address2,
  city,
  state,
  zip,
  country,
) {
  const line1 = [address1, address2].filter((a) => a).join(", ");

  const stateZip = [state, zip].filter((a) => a).join(" ");
  const line2 = [city, stateZip, country].filter((a) => a).join(", ");

  return [line1, line2].filter((a) => a).join("\n");
}

export function trimTrailingSlash(path) {
  if (path) {
    const { length } = path;
    if (path.charAt(length - 1) === "/") {
      return path.substr(0, length - 1);
    }
  }
  return path;
}

export function getNameDisplay(firstName, lastName, title) {
  return [title, firstName, lastName].filter((val) => !!val).join(" ");
}

export function getAddressDisplay(address) {
  if (!address) return "";

  return `${address.address1 ? `${address.address1}, ` : ""}
  ${address.address2 ? `${address.address2} ` : ""}
${address.city ? `${address.city}, ` : ""}${
    address.state ? `${address.state} ` : ""
  }
  ${address.zip ? `${address.zip}, ` : ""}${
    address.country ? `${address.country}` : ""
  }`;
}

export function getDateDisplay(date) {
  const dateParts = date.toString().split(/\s+/).slice(0, 3);
  return dateParts.slice(0, 2).join(", ") + " " + dateParts[2];
}

export function getCurrencyDisplay(amount) {
  if (!amount) return "$0";

  const value = parseFloat(amount).toFixed(2);
  const valueParts = value.toString().split(".");
  return (
    "$" +
    valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (valueParts[1] ? "." + valueParts[1] : "")
  );
}

export function getYearOptions(startYear, endYear) {
  const numberOfYears = endYear - startYear + 1;
  return [...Array(numberOfYears).keys()].map((i) => i + startYear);
}

export function pluralizeText(text, count, altPluralization) {
  return count === 1 ? text : altPluralization || text + "s";
}

export function removeEmptyFromObj(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmptyFromObj(obj[key]);
    else if (obj[key] === null || obj[key] === "") delete obj[key];
  });
}

export function nth(n) {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
}

export function getFormattedValuesForForm(values, dateFields) {
  const formattedValues = _cloneDeep(values);

  replaceValuesInObject(
    formattedValues,
    (val) => val === null,
    () => "",
  );

  if (dateFields) {
    dateFields.forEach((dateFieldName) => {
      const dateField = _get(formattedValues, dateFieldName);
      _set(formattedValues, dateFieldName, formatDateForInput(dateField));
    });
  }

  return formattedValues;
}

export function replaceValuesInObject(obj, toReplace, replaceWith) {
  //toReplace: (value, key) => {}, replaceWith: (valueToReplace) => replacementValue
  Object.keys(obj).forEach((key) => {
    if (toReplace(obj[key], key)) {
      obj[key] = replaceWith(obj[key]);
    } else if (obj[key]) {
      if (obj[key] instanceof Array) {
        obj[key].forEach((rec) =>
          replaceValuesInObject(rec, toReplace, replaceWith),
        );
      } else if (obj[key] instanceof Object) {
        replaceValuesInObject(obj[key], toReplace, replaceWith);
      }
    }
  });
}

export function flattenArray(arr) {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten,
    );
  }, []);
}

export function flattenObj(
  obj, // : Object
  except = (_) => false, // : (val: any) => boolean
  prefix = "",
  res = {},
) {
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`;
    if (typeof val === "object" && !except(val)) {
      flattenObj(val, except, `${k}.`, r);
    } else {
      res[k] = val;
    }
    return r;
  }, res);
}

export function pickValues(obj, propName) {
  const values = [];

  function _pick(o) {
    Object.keys(o).forEach((key) => {
      if (o[key] !== null && typeof o[key] === "object") _pick(o[key]);
      else if (key === propName) values.push(o[key]);
    });
  }

  _pick(obj);

  return values;
}

export function mapToObject(arr, keyFunc, valueFunc) {
  return arr.reduce((map, item) => {
    map[keyFunc(item)] = valueFunc(item);
    return map;
  }, {});
}

export function distinct(arr) {
  return arr.filter((value, index) => arr.indexOf(value) === index);
}

export function slugify(str, trimTrailingSlashes) {
  if (str) {
    str = str.toLowerCase();

    // Remove all accents
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    str = str
      .replace(/[^a-z0-9 -]/g, " ") // replace invalid chars with whitespace
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    if (trimTrailingSlashes) {
      str = str.replace(/^-+|-+$/g, ""); // remove leading, trailing -
    }
  }

  return str;
}

// get a versioned image source path when image at static path is updated
export function getVersionedImgSrc(fileName) {
  return `/images/${fileName}?${process.env.REACT_APP_IMG_CACHE_VERSION}`;
}

export function getCociLogo() {
  return getVersionedImgSrc("coci-logo.png");
}

export function getCociLogoMobile() {
  return getVersionedImgSrc("coci-logo-mobile.png");
}

export const PROFILE_PLACEHOLDER_URL = "/images/profile-placeholder.svg";

export function sum(vals) {
  return vals.reduce((total, val) => total + parseFloat(val || 0), 0);
}

export function parseNumber(numString) {
  return numString ? parseFloat(numString.toString().replace(/,/g, "")) : "";
}

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function toFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);

  return formData;
}

export class TwoWayMap {
  constructor(map) {
    this.map = map;
    this.reverseMap = {};
    for (let key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }
  get(key) {
    return this.map[key];
  }
  revGet(key) {
    return this.reverseMap[key];
  }
}
export function getCcIcon(ccBrand) {
  let ccImgSrc;
  switch (ccBrand) {
    case "Visa":
      ccImgSrc = "/images/cc-icons/visa.png";
      break;
    case "American Express":
      ccImgSrc = "/images/cc-icons/amex.png";
      break;
    case "MasterCard":
      ccImgSrc = "/images/cc-icons/mastercard.png";
      break;
    case "Discover":
      ccImgSrc = "/images/cc-icons/discover.png";
      break;
    default:
      ccImgSrc = "";
  }

  return ccImgSrc ? (
    <img src={ccImgSrc} alt="cc icon" height="24" className="mr-8" />
  ) : null;
}
export function getBannersToShow(banners = [], pathname) {
  return banners.filter(
    (banner) =>
      pathname
        .toLowerCase()
        .indexOf(banner.linkURL.split("?")[0].toLowerCase()) !== 0 && //don't show banner when on page banner is linked to
      new Date(banner.startShowing) <= new Date() &&
      new Date(banner.stopShowing) > new Date(),
  );
}
export function convertFromMilitaryTime(time) {
  const hoursRegex = /(^[^:]+)/g;
  let hours = time?.match(hoursRegex);
  hours = parseInt(hours);
  if (!hours && hours !== 0) {
    return null;
  }

  const suffix = hours >= 12 ? " pm" : " am";
  const newHours = ((hours + 11) % 12) + 1;
  return time.replace(hoursRegex, newHours) + suffix;
}

// Check size of window to be consisten with our scss definition of mobile
export function isMobileView() {
  return window.innerWidth <= 560;
}
export function isSmallTabletView() {
  return window.innerWidth > 560 && window.innerWidth <= 790;
}
export function isTabletView() {
  return window.innerWidth > 560 && window.innerWidth <= 1040;
}

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function convertToArray(value) {
  if (Array.isArray(value)) {
    return value;
  }
  if (value == null || value === "") {
    return [];
  }
  return [value];
}

export function uniqueArray(array) {
  return array.filter((value, index, arr) => arr.indexOf(value) === index);
}

export function hasValue(number) {
  return number || number === 0;
}

/**
 * Load document script
 * Primary source for this function https://stackoverflow.com/a/72210710/12892971
 */
export function loadScript(src, async = true, defer = true) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);

    const script = document.createElement("script");
    script.src = src;
    script.async = async;
    script.defer = defer;
    script.onload = () => resolve(true);
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });
}

//returns an array with 2 nested arrays - the first is where the condition is true, the second is where the condition is false
export function partition(array, condition) {
  if (!array?.length) {
    return [[], []];
  }
  return [
    array.filter((a) => condition(a)),
    array.filter((b) => !condition(b)),
  ];
}

// returns whether the arrays share any values
export function doArraysIntersect(arr1, arr2) {
  return arr1?.some((a) => arr2?.includes(a));
}

//distinct on should equal a function that return the property that we can filter on
export function distinctObjects(array, distinctOn) {
  if (!array?.length) {
    return [];
  }
  var newArray = [...array.map((o) => distinctOn(o))];
  newArray = newArray.filter((v, i, self) => i === self.indexOf(v));
  return newArray.map((a) => ({ ...array.find((t) => distinctOn(t) === a) }));
}

export function getEduEmailPreviewText(type, programName = "JewishU") {
  switch (type) {
    case "course_reject":
      return [
        "Dear [Student name],",
        <br />,
        <br />,
        "Thank you for applying to [course Name]",
        <br />,
        <br />,
        `${programName} has limited spots and Credit allotments, and must consider a combination of factors to determine whom those spots are allotted to for each course. We are sorry we could not give you a spot in this particular course.`,
        <br />,
        <br />,
        "Click here to browse and select from additional course offerings.",
        <br />,
        <br />,
        "Sincerely,",
        <br />,
        `${programName} Administrator`,
      ];
    case "global_reject":
      return [
        "Dear [Student name],",
        <br />,
        <br />,
        `Thank you for applying to ${programName}.`,
        <br />,
        <br />,
        `${programName} has various limitations on its acceptance abilities and must often turn down quality applicants. We regret to inform you that your application to ${programName} wasn't accepted. `,
        "We wish you the best of luck in all your studies.",
        <br />,
        <br />,
        "Sincerely,",
        <br />,
        `${programName} Administrator`,
      ];
    default:
      return "";
  }
}

export function getTripEmailPreviewText(data = {}) {
  const {
    studentFirstName = "[Student name]",
    campusName = "[Campus name]",
    programScheduleName = "[Event name]",
  } = data;

  return [
    "B”H",
    <br key="1" />,
    <br key="2" />,
    `Dear ${studentFirstName},`,
    <br key="3" />,
    <br key="4" />,
    `Thank you for your application. We regret to inform you that we will not be able to accommodate you as part of the ${campusName} group. We look forward to hosting you at a future event.`,
    <br key="5" />,
    <br key="6" />,
    `Your credit card has not been charged.`,
    <br key="7" />,
    <br key="8" />,
    "Best wishes for success,",
    <br key="9" />,
    `The ${programScheduleName} Team`,
  ];
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function scrollToBottom(elementRef) {
  elementRef.scrollTop = elementRef.scrollHeight;
}

// The following set of functions mirror the behavior of the intern-specific AuthSelectors
export function canManageStudents(auth) {
  return (
    auth.loggedInAs === AuthTypes.shliach ||
    auth.permissionClaims.includes(InternPermissions.manageStudents)
  );
}
export function canManageActivity(auth) {
  return (
    auth.loggedInAs === AuthTypes.shliach ||
    auth.permissionClaims.includes(InternPermissions.manageActivity)
  );
}
export function canManageRsvp(auth) {
  return (
    auth.loggedInAs === AuthTypes.shliach ||
    auth.permissionClaims.includes(InternPermissions.manageRsvp)
  );
}
export function canManageEduAttendance(auth) {
  return (
    auth.loggedInAs === AuthTypes.shliach ||
    auth.permissionClaims.includes(InternPermissions.eduAttendance)
  );
}
export function canManageEduSchedule(auth) {
  return (
    auth.loggedInAs === AuthTypes.shliach ||
    auth.permissionClaims.includes(InternPermissions.eduSchedule)
  );
}
export function canManageEduStudents(auth) {
  return (
    auth.loggedInAs === AuthTypes.shliach ||
    auth.permissionClaims.includes(InternPermissions.eduStudents)
  );
}
export function canManageRaffleDonations(auth) {
  return (
    auth.loggedInAs === AuthTypes.shliach ||
    auth.permissionClaims.includes(InternPermissions.manageRaffleDonations)
  );
}

export function hasTrueFalseValue(value) {
  return value === true || value === false;
}

export function joinCommaDelimitedList(items) {
  return items
    ? [items.slice(0, -1).join(", "), items.slice(-1)[0]].join(
        items.length < 2 ? "" : " and ",
      )
    : "";
}
