// #region static filter options

import { convertToArray, getYearOptions } from "../../../lib";

export const classesOptions = [
  { label: "Grad", value: "Grad" },
  { label: "Undergrad", value: "Undergrad" },
  { label: "Alumnus", value: "Alumnus" },
  { label: "Unknown", value: "Unknown" },
];

export const classesFilterDefaults = classesOptions.filter(
  (cl) => cl.value !== "Alumnus",
);

export const genderOptions = [
  { label: "All genders", value: "" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Not Set", value: "Unknown" },
];

export const gradYearOptions = getYearOptions(
  2005,
  new Date().getFullYear() + 15,
).map((yr) => ({
  value: yr,
  label: yr,
}));

export const sortOptions = [
  { label: "Last Name", value: "lastName" },
  { label: "First Name", value: "firstName" },
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
];

export const engagementRoundupOptions = [
  { label: "All monthly engagement roundup", value: "" },
  { label: "New students", value: "NewStudents" },
  { label: "Month absentees", value: "MonthAbsentees" },
  { label: "Frequent student absentees", value: "FrequentStudentAbsentees" },
  {
    label: "Interested student absentees",
    value: "InterestedStudentAbsentees",
  },
  {
    label: "Connected student absentees",
    value: "ConnectedStudentAbsentees",
  },
];

// #endregion

export const getStudentsListQueryFilters = (query) => {
  const {
    ca: campusId,
    cl: classes,
    cldo: classesDefaultOverride,
    er: engagementRoundupFilter,
    ge: gender,
    gr: chabadHouseTag,
    gy: graduationYear,
    jil: jewishInterestLevel,
    se: search,
    so: sortBy,
  } = query;

  return {
    campusId: campusId ? [campusId] : undefined,
    chabadHouseTags: chabadHouseTag ? [chabadHouseTag] : undefined,
    classes: classesDefaultOverride
      ? classes
        ? convertToArray(classes)
        : undefined
      : classesFilterDefaults.map((cl) => cl.value),
    engagementRoundupFilter,
    gender,
    graduationYear: graduationYear ? convertToArray(graduationYear) : undefined,
    jewishInterestLevel,
    sortByOption: sortBy || "lastName", // default sortBy option
    text: search ? decodeURIComponent(search) : search,
  };
};
