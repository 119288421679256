import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Box, Tab, Tabs } from "@material-ui/core";

import {
  Navigation,
  isMobileView,
  isTabletView,
  doArraysIntersect,
} from "../../../lib";

import RaffleInfoCard from "./RaffleInfoCard";
import ProgramBreadcrumbsHeader from "../../../components/ProgramBreadcrumbsHeader";
import { InternPermissions } from "../../../../src/lib/constants";
import { AuthSelectors } from "../../../state";

const raffleTabs = [
  { label: "Metrics", value: "metrics", internPermissions: [] },
  {
    label: "Orders",
    value: "orders",
    internPermissions: [InternPermissions.manageRaffleDonations],
  },
  { label: "Settings", value: "settings", internPermissions: [] },
  { label: "Teams", value: "teams", internPermissions: [] },
  {
    label: "Master Donors List",
    value: "donors",
    internPermissions: [InternPermissions.manageRaffleDonations],
  },
];

const RaffleSidebar = (props) => {
  const {
    raffle,
    getHistoricRaffle,
    pageRoute,
    tabContentShowing,

    showMenu,
    programScheduleID,
    isShliach,
  } = props;

  const { historicEnrollments, success, details } = raffle;
  const [selectedRaffle, setSelectedRaffle] = useState(null);

  const userClaims = useSelector(AuthSelectors.claims);
  const defaultTab = isShliach ? "metrics" : "orders";
  const getSelectedRaffle = useCallback(() => {
    const currentRaffle =
      historicEnrollments &&
      historicEnrollments
        .filter(
          (enrollment) =>
            //on error, sidebar is shown but there is no id
            enrollment.raffleEventID === details?.id,
        )
        .map((enrollment) => ({
          key: enrollment,
          value: enrollment,
          label: enrollment.raffleTitle,
        }))[0];
    setSelectedRaffle(currentRaffle);
  }, [details, historicEnrollments]);

  const navigate = (page) => {
    Navigation.go(`${pageRoute.location.pathname}?tab=${page}`);
  };

  const onChange = (value) => {
    value = value.value;
    switchRaffles(value.sellerEnrollmentID, value.programScheduleID);
  };

  const switchRaffles = (id, scheduleID) => {
    getHistoricRaffle(id, scheduleID);
    const pageURL = pageRoute.location.pathname.slice(
      0,
      pageRoute.location.pathname.lastIndexOf("/"),
    );
    //keep same tab selected when switch between raffle years
    if (tabContentShowing) {
      Navigation.redirect(`${pageURL}/${scheduleID}?tab=${tabContentShowing}`);
    } else {
      Navigation.redirect(`${pageURL}/${scheduleID}?tab=${defaultTab}`);
    }
  };

  useEffect(() => {
    getSelectedRaffle();
  }, [getSelectedRaffle]);

  const availableRaffleTabs = showMenu
    ? isShliach
      ? raffleTabs
      : raffleTabs.filter((tab) =>
          doArraysIntersect(userClaims, tab.internPermissions),
        )
    : [];

  return (
    <div className="raffles-sidebar">
      <Box className="raffles-sidebar-actions mb-16">
        {isShliach && (
          <ProgramBreadcrumbsHeader
            className="desktop-hidden "
            scheduleId={programScheduleID}
          />
        )}
        <Select
          className={`custom-select`}
          classNamePrefix="custom-select"
          name={"selectedRaffle"}
          onChange={(option) => onChange(option)}
          options={
            historicEnrollments &&
            historicEnrollments.map((enrollment) => ({
              key: enrollment,
              value: enrollment,
              label: enrollment.raffleTitle,
            }))
          }
          placeholder={"Select past raffle..."}
          styles={{
            indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
            placeholder: (styles) => ({ ...styles, fontSize: "12px" }),
            clearIndicator: (styles) => ({
              ...styles,
              marginRight: "-8px",
              padding: "0",
            }),
          }}
          value={selectedRaffle}
        />
      </Box>
      <Box className="raffles-sidebar-content">
        {showMenu && (
          <Box className="raffles-sidebar-card raffles-menu">
            <Tabs
              variant="scrollable"
              indicatorColor="primary"
              orientation={
                isMobileView() || isTabletView() ? "horizontal" : "vertical"
              }
              textColor="primary"
              value={tabContentShowing || availableRaffleTabs[0].value} // default to the first tab to avoid 'out-of-range value' error
              onChange={(_, value) => {
                navigate(value);
              }}
              scrollButtons={
                isMobileView() && availableRaffleTabs.length > 3 ? "on" : "auto"
              }
            >
              {availableRaffleTabs.map((tab) => {
                return (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                );
              })}
            </Tabs>
            <span className="xs-text block mt-8 tablet-hidden mobile-hidden">
              With year-by-year comparison
            </span>
          </Box>
        )}
        {success && <RaffleInfoCard />}
      </Box>
    </div>
  );
};

export default React.memo(RaffleSidebar);
