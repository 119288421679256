import React from "react";
import Select from "react-select";

export default class MultiSelect extends React.Component {
  fixedOptionStyles = {
    multiValue: (base, state) =>
      state.data.isFixed ? { ...base, backgroundColor: "gray" } : base,
    multiValueLabel: (base, state) =>
      state.data.isFixed ? { ...base, color: "white", paddingRight: 6 } : base,
    multiValueRemove: (base, state) =>
      state.data.isFixed ? { ...base, display: "none" } : base,
  };

  orderOptions = (options) => options.sort((a, _) => (a.isFixed ? -1 : 1));

  onChange = (
    newValue,
    actionMeta,
    isFixedValuesFirst,
    originalOptions,
    name,
    propsOnChange,
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue?.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = originalOptions.filter((v) => v.isFixed);
        break;
      default:
    }

    if (isFixedValuesFirst) {
      newValue = this.orderOptions(newValue);
    }
    this.setState({ value: newValue });

    propsOnChange(name, newValue);
  };

  render() {
    const {
      disabled,
      className,
      components,
      filterOption,
      isClearable,
      isFixedValuesFirst,
      isOptionDisabled,
      menuPlacement,
      noOptionsMessage,
      options,
      onChange,
      placeholder,
      name,
      value,
      styles,
    } = this.props;
    return (
      <Select
        components={components}
        className={`multiselect ${className || ""}`}
        classNamePrefix="multiselect"
        closeMenuOnSelect={false}
        filterOption={filterOption}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={true}
        isOptionDisabled={isOptionDisabled}
        menuPlacement={menuPlacement}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onChange={(newValue, actionMeta) =>
          this.onChange(
            newValue,
            actionMeta,
            isFixedValuesFirst,
            options,
            name,
            onChange,
          )
        }
        options={options}
        placeholder={placeholder}
        styles={{ ...this.fixedOptionStyles, ...styles }}
        value={value}
      />
    );
  }
}
