import React from "react";
import { formatAddressDisplay } from "../../../lib";

export default class ChabadHouseDetails extends React.PureComponent {
  render() {
    const { chabadHouse } = this.props;
    const {
      address,
      campuses,
      name,
      primaryEmail,
      primaryPhoneNumber,
      region,
      timezoneName,
      websiteURL,
      yearEstablished,
    } = chabadHouse || {};

    return chabadHouse ? (
      <div className="chabad-house-details">
        <div>
          <div className="mb-16">
            <p className="uppercase-text mb-8">Chabad House</p>
            <p>{name}</p>
          </div>
          <div className="two-column-grid">
            {primaryPhoneNumber && (
              <React.Fragment>
                <p>Phone</p>
                <a
                  className="link-text-secondary"
                  href={`tel:${primaryPhoneNumber}`}
                >
                  {primaryPhoneNumber}
                </a>
              </React.Fragment>
            )}
            {primaryEmail && (
              <React.Fragment>
                <p>Email</p>
                <a
                  className="link-text-secondary"
                  href={`mailto:${primaryEmail}`}
                >
                  {primaryEmail}
                </a>
              </React.Fragment>
            )}
            {address && (
              <React.Fragment>
                <p>Address</p>
                <div className="pre-wrap">
                  {formatAddressDisplay(
                    address.address1,
                    address.address2,
                    address.city,
                    address.state,
                    address.zip,
                    address.country,
                  )}
                </div>
              </React.Fragment>
            )}
            {websiteURL && (
              <React.Fragment>
                <p>Website</p>
                <a
                  className="link-text"
                  href={websiteURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {websiteURL}
                </a>
              </React.Fragment>
            )}
            {websiteURL && (
              <React.Fragment>
                <p>Established</p>
                <p>{yearEstablished}</p>
              </React.Fragment>
            )}
            {region && (
              <React.Fragment>
                <p>Region</p>
                <p>{region}</p>
              </React.Fragment>
            )}
            {timezoneName && (
              <React.Fragment>
                <p>Timezone</p>
                <p>{timezoneName}</p>
              </React.Fragment>
            )}
          </div>
        </div>
        <div>
          <p className="uppercase-text mb-16">Schools</p>
          {campuses &&
            campuses.map((campus, index) => (
              <div
                className="flex flex-align-center flex-justify-space mb-16"
                key={index}
              >
                <p>{campus.campusName}</p>
                {campus.isPrimary && <p className="accent-text">Primary</p>}
              </div>
            ))}
        </div>
      </div>
    ) : null;
  }
}
