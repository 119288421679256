import React from "react";
import Toggle from "../../../components/form/Toggle";
import { getYearOptions, Navigation, PageLink } from "../../../lib";
import Select from "react-select";
import FadeOutErrorMessage from "../../../components/FadeOutErrorMessage";
import Loader from "../../../components/Loader";
import { AsyncPaginate } from "react-select-async-paginate";
import Button from "@material-ui/core/Button";
import { getRecipientStudentsOptions } from "../smsUtils";
import MaterialCheckbox from "../../../components/form/MaterialCheckbox";
import { Typography } from "@material-ui/core";
import Pages from "../..";

export default class SelectRecipients extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      pageRoute: {
        query: { filterByEventId, filterByResourceId },
      },
    } = this.props;
    this.state = {
      errorMessage: "",
      loading: true,
      touched: false,
      filters: {
        classes: [],
        programsParticipated: [],
        excludeIfParticipatedIn: [],
        campusIDs: [],
        graduationYears: [],
        includeArchived: false,
        isJewish: true,
        gender: null,
        chabadHouseTags: [],
        ...props.filters,
      },
      programs: [],
      recipientsAddByGroup:
        !!props.filters || !!filterByEventId || !!filterByResourceId,
      singleRecipients: [],
    };
  }

  programParticipationTypes = {
    program: "program",
    rsvpInteractionType: "rsvpInteractionType",
    event: "event",
    resource: "resource",
  };

  componentDidMount() {
    this.loadPageData();
  }

  loadPageData = async () => {
    const {
      actions: {
        clearSms,
        getRsvpEnrollment,
        getRsvpEventSchedules,
        getRsvpResources,
        getSchools,
        getAllChabadHouseTags,
      },
      shliachID,
    } = this.props;

    clearSms(false);
    await getRsvpEnrollment();
    const {
      enrollment: {
        data: {
          forChabadHouseID,
          isChabadHouseEnrolledInOutgoingMessaging,
        } = {},
      } = {},
      pageRoute: {
        query: { filterByEventId, filterByResourceId },
      },
    } = this.props;
    if (forChabadHouseID && isChabadHouseEnrolledInOutgoingMessaging) {
      await getSchools(shliachID);
      await getRsvpEventSchedules(1, 10, {
        export: true,
        includeDetails: true,
      });
      await getRsvpResources(1, 10, {
        export: true,
        includeDetails: true,
      });
      await getAllChabadHouseTags();
      await this.getProgramsAndCreateList();

      if (filterByEventId) {
        await this.props.actions.getRsvpEventSchedule(filterByEventId);

        const { eventSchedule: { data: { name } = {} } = {} } = this.props;

        this.setState({
          filters: {
            ...this.state.filters,
            programsParticipated: [
              {
                value: filterByEventId,
                programParticipationType: this.programParticipationTypes.event,
                label: `RSVP Event - ${name}`,
              },
            ],
          },
        });
      }

      if (filterByResourceId) {
        await this.props.actions.getRsvpResource(filterByResourceId);

        const { resource: { data: { name } = {} } = {} } = this.props;

        this.setState({
          filters: {
            ...this.state.filters,
            programsParticipated: [
              {
                value: filterByResourceId,
                programParticipationType:
                  this.programParticipationTypes.resource,
                label: `RSVP Resource - ${name}`,
              },
            ],
          },
        });
      }
    }

    this.setState({ loading: false });
  };

  submit = async () => {
    const {
      actions: { getBulkRecipients, setSmsFilters, clearSmsFilters },
      singleRecipients,
      goToNextStep,
      page: { nextStep },
    } = this.props;
    const { recipientsAddByGroup } = this.state;

    if (recipientsAddByGroup) {
      let filters = { ...this.state.filters };
      setSmsFilters(filters);

      //events
      filters.rsvpEventsParticipated = filters.programsParticipated.filter(
        (e) =>
          e.programParticipationType === this.programParticipationTypes.event,
      );
      filters.excludeParticipatedInRsvpEvents =
        filters.excludeIfParticipatedIn.filter(
          (e) =>
            e.programParticipationType === this.programParticipationTypes.event,
        );

      //resources
      filters.rsvpResourcesParticipated = filters.programsParticipated.filter(
        (e) =>
          e.programParticipationType ===
          this.programParticipationTypes.resource,
      );
      filters.excludeParticipatedInRsvpResources =
        filters.excludeIfParticipatedIn.filter(
          (e) =>
            e.programParticipationType ===
            this.programParticipationTypes.resource,
        );

      //rsvp interaction types
      filters.rsvpTypesParticipated = filters.programsParticipated.filter(
        (e) =>
          e.programParticipationType ===
          this.programParticipationTypes.rsvpInteractionType,
      );
      filters.excludeParticipatedInRsvpTypes =
        filters.excludeIfParticipatedIn.filter(
          (e) =>
            e.programParticipationType ===
            this.programParticipationTypes.rsvpInteractionType,
        );

      //programs
      filters.programsParticipated = filters.programsParticipated.filter(
        (e) =>
          e.programParticipationType === this.programParticipationTypes.program,
      );
      filters.excludeParticipatedInPrograms =
        filters.excludeIfParticipatedIn.filter(
          (e) =>
            e.programParticipationType ===
            this.programParticipationTypes.program,
        );

      for (const key of Object.keys(filters)) {
        if (!filters[key] || filters[key].length === 0) {
          delete filters[key];
        } else if (Array.isArray(filters[key])) {
          filters[key] = filters[key].map((c) => c.value);
        }
      }
      if (Object.keys(filters).length === 0) {
        this.setState({
          touched: false,
          errorMessage: "Please choose at least one filter",
        });
        return;
      }
      filters.includeArchived = this.state.filters.includeArchived;
      await getBulkRecipients(filters);
    } else {
      clearSmsFilters();
    }

    if (
      singleRecipients?.length > 0 ||
      this.props.bulkRecipients?.recipients?.length > 0
    ) {
      goToNextStep(nextStep);
    } else {
      this.setState({
        touched: false,
        errorMessage: recipientsAddByGroup
          ? "No Results, please update filters"
          : "Please select recipients",
      });
    }
  };

  getStudentOptions = () =>
    getRecipientStudentsOptions(this.props.students?.data?.results || []);

  getProgramsAndCreateList = async () => {
    const {
      actions: { getChabadHousePrograms },
      chabadHouseID,
    } = this.props;

    await getChabadHousePrograms(chabadHouseID);

    let { chabadHousePrograms: { data: programs } = {} } = this.props;
    const {
      sys: { interactionTypes = [] },
      eventSchedules: { data: { results: events } = {} } = {},
      resources: { data: { results: resources } = {} } = {},
    } = this.props;

    const programList =
      programs
        ?.filter((f) => f.programScheduleName !== "RSVP")
        .map((p) => {
          return {
            name: `${p.programName} - ${p.programScheduleName}`,
            id: p.programScheduleID,
            programParticipationType: this.programParticipationTypes.program,
          };
        }) || [];

    const rsvpInteractionTypes = interactionTypes
      .filter((t) => t.programType === "Rsvp")
      .map((r) => {
        return {
          name: r.name,
          id: r.id,
          programParticipationType:
            this.programParticipationTypes.rsvpInteractionType,
        };
      });

    const eventsList =
      events?.map((e) => ({
        name: `RSVP Event - ${e.name} - ${e.formattedDate}`,
        id: e.eventScheduleID,
        programParticipationType: this.programParticipationTypes.event,
      })) || [];

    const resourcesList =
      resources?.map((r) => ({
        name: `RSVP Resource - ${r.name}`,
        id: r.resourceID,
        programParticipationType: this.programParticipationTypes.resource,
      })) || [];

    const list = programList
      .concat(rsvpInteractionTypes)
      .concat(eventsList)
      .concat(resourcesList);
    this.setState({ programs: list });
  };

  gradYears = [
    ...getYearOptions(2005, new Date().getFullYear() + 15).map((yr) => ({
      value: yr,
      label: yr,
    })),
  ];

  render() {
    const {
      actions: { getStudents, setSmsSingleRecipients },
      enrollment: {
        data: {
          forChabadHouseID,
          isChabadHouseEnrolledInOutgoingMessaging,
        } = {},
      } = {},
      schools,
      singleRecipients,
      chabadHouseTags: { data: allChabadHouseTags = [] } = {},
      sys: { studentClasses },
    } = this.props;

    const {
      loading,
      programs,
      recipientsAddByGroup,
      errorMessage,
      touched,
      filters: {
        campusIDs,
        graduationYears,
        chabadHouseTags,
        excludeIfParticipatedIn,
        includeArchived,
        isJewish,
        gender,
        programsParticipated,
      },
    } = this.state;

    return (
      <div className="page">
        {loading ? (
          <Loader />
        ) : !forChabadHouseID || !isChabadHouseEnrolledInOutgoingMessaging ? (
          <div>
            <div className="message-status-content">
              <img
                width="400px"
                src="/images/message_sent_error.svg"
                alt="not configured for messaging"
              />
              <div className="message-container">
                <div className="message mt-48">
                  <p>
                    Sorry, your chabad house is not configured for sending sms
                    messages.
                  </p>
                  <p className="mt-16 large-text text-center line-height-double">
                    Questions? Please contact us at{" "}
                    <a
                      href="mailto:help@chabadoncampus.org"
                      className="link-text"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      help@chabadoncampus.org
                    </a>{" "}
                    or{" "}
                    <a href="tel:+1-718-510-8181,0" className="link-text">
                      718 510 8181 x 0
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Typography variant="h6" className="mb-8">
                Select recipients
              </Typography>
              <Typography
                variant="body2"
                className="mb-12"
                style={{ color: "#747579" }}
              >
                You can also message students from a specific event or resource
                within managing the event or resource
              </Typography>

              <div>
                <div className="sms-wizard-container mt-24">
                  <div className="flex flex-justify-space flex-align-center mobile-flex-column mobile-flex-align-top">
                    <div className="select-recipient-input-div">
                      <Toggle
                        name={`recipientsAddByGroup`}
                        options={[
                          { value: false, display: "Add students" },
                          { value: true, display: "Add by filters" },
                        ]}
                        value={recipientsAddByGroup}
                        onChange={(name, value) => {
                          this.setState({
                            recipientsAddByGroup: value,
                            touched: true,
                          });
                        }}
                      />
                    </div>
                    <div className="text-right">
                      <MaterialCheckbox
                        label="Include archived"
                        checked={includeArchived}
                        onChange={() => {
                          this.setState({
                            filters: {
                              ...this.state.filters,
                              includeArchived: !includeArchived,
                            },
                            touched: true,
                          });
                        }}
                      />
                    </div>
                  </div>

                  {recipientsAddByGroup && (
                    <div>
                      <Typography variant="h6" className="mb-8 mt-24">
                        Create filters to choose recipients
                      </Typography>
                      <div className="sms-wizard-filter">
                        <div className="select-recipient-label-div">
                          <label className="accent-text select-recipient-label">
                            School
                          </label>
                        </div>
                        <div className="select-recipient-input-div full-width">
                          <Select
                            placeholder="All..."
                            isMulti={true}
                            options={
                              schools?.map((s, i) => ({
                                ...s,
                                key: s.id,
                                value: s.id,
                                label: s.name,
                              })) || []
                            }
                            name={"recipients"}
                            value={campusIDs}
                            onChange={(v) => {
                              this.setState({
                                filters: {
                                  ...this.state.filters,
                                  campusIDs: v,
                                },
                                touched: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="sms-wizard-filter">
                          <div className="select-recipient-label-div">
                            <label className="accent-text select-recipient-label">
                              By student group
                            </label>
                          </div>
                          <div className="select-recipient-input-div full-width">
                            <Select
                              placeholder="All..."
                              isMulti={true}
                              options={
                                allChabadHouseTags?.map((s, i) => ({
                                  ...s,
                                  key: s.id,
                                  value: s.id,
                                  label: s.tagName,
                                })) || []
                              }
                              name={"tags"}
                              value={chabadHouseTags}
                              onChange={(v) => {
                                this.setState({
                                  filters: {
                                    ...this.state.filters,
                                    chabadHouseTags: v,
                                  },
                                  touched: true,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-8" style={{ textAlign: "right" }}>
                          <PageLink
                            className="link-text mt-8"
                            to={Pages.engagement.studentsGroups}
                          >
                            Manage groups
                          </PageLink>
                        </div>
                      </div>
                      <div className="checkbox-filter-div">
                        <label className="accent-text">Jewish</label>
                        <div>
                          <MaterialCheckbox
                            className="sms-wizard-checkbox"
                            label="Student Identifies as Jewish"
                            checked={isJewish}
                            onChange={() => {
                              this.setState({
                                filters: {
                                  ...this.state.filters,
                                  isJewish: !isJewish,
                                },
                                touched: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="checkbox-filter-div mb-16">
                        <label className="accent-text">Gender</label>
                        <div>
                          <MaterialCheckbox
                            className="sms-wizard-checkbox"
                            label="Male"
                            checked={gender === "Male"}
                            onChange={() => {
                              if (gender === "Male") {
                                this.setState({
                                  filters: {
                                    ...this.state.filters,
                                    gender: null,
                                  },
                                  touched: true,
                                });
                              } else {
                                this.setState({
                                  filters: {
                                    ...this.state.filters,
                                    gender: "Male",
                                  },
                                  touched: true,
                                });
                              }
                            }}
                          />
                          <MaterialCheckbox
                            className="sms-wizard-checkbox"
                            label="Female"
                            checked={gender === "Female"}
                            onChange={() => {
                              if (gender === "Female") {
                                this.setState({
                                  filters: {
                                    ...this.state.filters,
                                    gender: null,
                                  },
                                  touched: true,
                                });
                              } else {
                                this.setState({
                                  filters: {
                                    ...this.state.filters,
                                    gender: "Female",
                                  },
                                  touched: true,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="sms-wizard-filter-base">
                        <div className="select-recipient-label-div">
                          <label className="accent-text select-recipient-label">
                            Grad year
                          </label>
                        </div>
                        <div className="select-recipient-input-div full-width">
                          <Select
                            placeholder="All..."
                            isMulti={true}
                            options={this.gradYears.map((s, i) => ({
                              ...s,
                              key: i,
                              value: s.value,
                              label: `${s.label}`,
                            }))}
                            name={"recipients"}
                            value={graduationYears}
                            onChange={(v) => {
                              this.setState({
                                filters: {
                                  ...this.state.filters,
                                  graduationYears: v,
                                },
                                touched: true,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="sms-wizard-filter">
                        <div className="select-recipient-label-div">
                          <label className="accent-text select-recipient-label">
                            Demographic
                          </label>
                        </div>
                        <div className="select-recipient-input-div full-width">
                          <Select
                            placeholder="All..."
                            isMulti={true}
                            options={studentClasses?.map((s, i) => ({
                              key: i,
                              value: s.intValue,
                              label: `${s.displayValue}`,
                            }))}
                            name={"recipients"}
                            value={this.state.filters.classes}
                            onChange={(v) => {
                              this.setState({
                                filters: { ...this.state.filters, classes: v },
                                touched: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="sms-wizard-filter">
                        <div className="select-recipient-label-div">
                          <label className="accent-text select-recipient-label">
                            Participated / registered for
                          </label>
                        </div>
                        <div className="select-recipient-input-div full-width">
                          <Select
                            placeholder="Select..."
                            isMulti={true}
                            options={programs?.map((p, i) => ({
                              ...p,
                              key: i,
                              value: p.id,
                              label: `${p.name}`,
                            }))}
                            name={"recipients"}
                            value={programsParticipated}
                            onChange={(v) => {
                              this.setState({
                                filters: {
                                  ...this.state.filters,
                                  programsParticipated: v,
                                },
                                touched: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="sms-wizard-filter">
                        <div className="select-recipient-label-div">
                          <label className="accent-text select-recipient-label">
                            Exclude Participated in
                          </label>
                        </div>
                        <div className="select-recipient-input-div full-width">
                          <Select
                            placeholder="Select..."
                            isMulti={true}
                            options={programs?.map((p, i) => ({
                              ...p,
                              key: i,
                              value: p.id,
                              label: `${p.name}`,
                            }))}
                            value={excludeIfParticipatedIn}
                            onChange={(v) => {
                              this.setState({
                                filters: {
                                  ...this.state.filters,
                                  excludeIfParticipatedIn: v,
                                },
                                touched: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <Typography variant="h6" className="mb-8 mt-24">
                    {recipientsAddByGroup
                      ? "Add additional individual students"
                      : "Students"}
                  </Typography>
                  {!recipientsAddByGroup && (
                    <div className="mb-12 accent-text">
                      Please select recipients who will receive this SMS
                      message.
                    </div>
                  )}
                  <div className="mb-24">
                    <AsyncPaginate
                      placeholder="Select..."
                      value={singleRecipients}
                      cacheUniqs={[includeArchived]}
                      loadOptions={async (search, _, { page }) => {
                        await getStudents(page, 50, {
                          text: search,
                          includeArchived,
                          includeDetails: true,
                        });
                        const hasMore =
                          this.props.students?.data?.numberOfRows > page * 50;
                        return {
                          options: this.getStudentOptions(),
                          hasMore,
                          additional: {
                            page: page + 1,
                          },
                        };
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={(v) => {
                        this.setState({ touched: true });
                        setSmsSingleRecipients(v);
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-justify-space flex-align-center mt-24">
              <div>
                <Button
                  variant="contained"
                  size="large"
                  className="sms-button-secondary"
                  disableElevation
                  onClick={() => {
                    Navigation.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <div>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    className="sms-button-primary ml-16"
                    size="large"
                    onClick={this.submit}
                  >
                    Next
                  </Button>
                </div>
                {!touched && (
                  <FadeOutErrorMessage
                    message={errorMessage}
                    onTimeout={() => {
                      this.setState({ errorMessage: "" });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
