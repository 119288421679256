const newArray: any[] = [];
const emptyObject = {};
export const SmsSelectors = {
  outbox(state: any) {
    return state.sms.outbox?.data?.results;
  },
  outboxLoading(state: any) {
    return state.sms.outbox?.loading;
  },
  outboxError(state: any) {
    return state.sms.outbox?.error;
  },
  inbox(state: any) {
    return state.sms.inbox?.data?.results;
  },
  inboxLoading(state: any) {
    return state.sms.inbox?.loading;
  },
  inboxError(state: any) {
    return state.sms.inbox?.error;
  },
  statementData(state: any) {
    return state.sms.statement?.data || emptyObject;
  },
  scheduledMessages(state: any) {
    return state.sms.scheduledMessages?.data?.results;
  },
  scheduledMessagesLoading(state: any) {
    return state.sms.scheduledMessages?.loading;
  },
  scheduledMessagesError(state: any) {
    return state.sms.scheduledMessages?.error;
  },
  messageThread(state: any) {
    return state.sms.messageThread?.data || newArray;
  },
  messageThreadLoading(state: any) {
    return state.sms.messageThread?.loading;
  },
};
