import axios from "axios";

export const UpdatesActions = {
  SET_UPDATES_LOADING: "SET_UPDATES_LOADING",
  UPDATE_UPDATES: "UPDATE_UPDATES",

  getPosts(results, searchTerm = "") {
    return async (dispatch, getState) => {
      dispatch(UpdatesActions.setUpdatesLoading(true));
      let success = true;
      const { data } = await axios
        .get(
          `https://chabadoncampus.org/wp-json/wp/v2/posts?per_page=100&search=${searchTerm}`,
        )
        .catch((err) => {
          success = false;
          return err;
        });
      const { data: posts } = await axios
        .get(
          `https://chabadoncampus.org/wp-json/wp/v2/posts?per_page=${results}&search=${searchTerm}&_embed`,
        )
        .catch((err) => {
          success = false;
          return err;
        });
      const newState = {
        totalPosts: data?.length,
        posts: posts,
        loading: false,
        success,
      };
      dispatch(UpdatesActions.updateUpdates(newState));
    };
  },
  setUpdatesLoading(payload) {
    return {
      type: UpdatesActions.SET_UPDATES_LOADING,
      payload,
    };
  },
  updateUpdates(payload) {
    return {
      type: UpdatesActions.UPDATE_UPDATES,
      payload,
    };
  },
};
