import React, { memo } from "react";
import {
  Avatar,
  Button,
  List,
  ListItem,
  Typography,
  Box,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobileView, isTabletView, Navigation, PageURL } from "../../lib";
import {
  getNamesForList,
  getPhoneFormatted,
  messagesView,
  stringAvatar,
} from "../rsvp/myRsvp/RsvpMessageUtils";
import { useSelector } from "react-redux";
import { SmsSelectors } from "../../state/sms/selectors";
import Loader from "../../components/Loader";
import { messageRecipientLimit } from "./smsUtils";
// import Search from "../../components/Search";
// import { useLocation } from "react-router-dom";
// import { useQuery } from "../../lib/hooks";

type Message = {
  body: string | null;
  media: string[];
  direction: "OutboundApi" | "Inbound" | "OutboundReply";
  isAutomaded: boolean;
  messageId: string;
  toPersonPhone: string | null;
  fromPersonPhone: string | null;
  firstPersonStudentId: number | null;
  firstPersonName: string;
  firstPersonId: number;
  totalRecipients: number;
  smsBlastId: number | null;
  time: string;
};

interface Props {
  activeMessage: Message;
  data: Message[];
  getMessageThread: any;
  params: any;
  handleFetchMore: any;
  setShowActiveMessageModal: any;
  timezoneID: any;
}

const SmsMessageList = ({
  activeMessage,
  data = [],
  getMessageThread,
  params,
  handleFetchMore,
  setShowActiveMessageModal,
  timezoneID,
}: Props) => {
  const { view } = params;
  // const query = useQuery();
  // const { search = "" } = query;

  const inboxLoading = useSelector(SmsSelectors.inboxLoading);
  const outboxLoading = useSelector(SmsSelectors.outboxLoading);
  const scheduledMessagesLoading = useSelector(
    SmsSelectors.scheduledMessagesLoading,
  );

  const inboxError = useSelector(SmsSelectors.inboxError);
  const outboxError = useSelector(SmsSelectors.outboxError);
  const scheduledMessagesError = useSelector(
    SmsSelectors.scheduledMessagesError,
  );

  // const { pathname } = useLocation();

  ////hiding search input for now - on search input change update search param
  // const onChange = (name: string, val: any) => {
  //   const url = PageURL.to(pathname, params, {
  //     ...query,
  //     [name]: val,
  //   });
  //   Navigation.redirect(url);
  // };

  const finishedLoading =
    (view === messagesView.inbox && inboxLoading === false) ||
    (view === messagesView.sent && outboxLoading === false) ||
    (view === messagesView.scheduled && scheduledMessagesLoading === false);

  const showError = !!(view === messagesView.inbox
    ? inboxError
    : view === messagesView.sent
    ? outboxError
    : view === messagesView.scheduled && scheduledMessagesError);

  return (
    <List className="mailbox-list">
      {data.length > 0 ? (
        <>
          <div className="mailbox-header">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="mailbox-header-title">{view}</div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  Navigation.go(PageURL.to("/sms/wizard"));
                }}
              >
                <Add /> Sms
              </Button>
            </div>
            {/* <Search
              value={search || ""}
              onSearch={onChange}
              style={{ width: "auto", marginTop: 16 }}
              placeholder="Search messages"
              name="search"
            /> */}
          </div>
          <InfiniteScroll
            dataLength={data.length}
            next={handleFetchMore}
            style={{
              overflowY: "scroll",
              borderRight: ".5px solid #d3d3d3b5",
            }}
            height={"calc(100vh - 80px)"}
            hasMore={true}
            loader={<></>}
          >
            {data?.map((msg: Message, i: number) => {
              const { name, extraRecipients } = getNamesForList(msg);
              const { toPersonPhone, fromPersonPhone, totalRecipients } = msg;
              const phoneNumber = toPersonPhone
                ? getPhoneFormatted(toPersonPhone)
                : fromPersonPhone
                ? getPhoneFormatted(fromPersonPhone)
                : null;
              const moreThanMessageRecipientLimit =
                totalRecipients > messageRecipientLimit;

              return (
                <ListItem
                  divider={true}
                  button
                  key={i}
                  selected={activeMessage?.messageId === msg.messageId}
                  onClick={() => {
                    getMessageThread(msg);
                    setShowActiveMessageModal(isMobileView() || isTabletView());
                  }}
                >
                  <ListItemAvatar>
                    {name ? <Avatar {...stringAvatar(name)} /> : <Avatar />}
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Box className="flex flex-justify-space">
                        <Typography variant="subtitle2">
                          {view === messagesView.inbox ? "From" : "To"}:{" "}
                          <span
                            className={`${
                              msg.firstPersonStudentId &&
                              !moreThanMessageRecipientLimit
                                ? "accent-text-secondary pointer"
                                : ""
                            }`}
                            onClick={() =>
                              msg.firstPersonStudentId &&
                              !moreThanMessageRecipientLimit &&
                              Navigation.go(
                                PageURL.to(
                                  `/engagement/students/${msg.firstPersonStudentId}`,
                                ),
                              )
                            }
                          >
                            {moreThanMessageRecipientLimit
                              ? `${messageRecipientLimit}+ recipients`
                              : name ?? phoneNumber}
                          </span>
                          {!moreThanMessageRecipientLimit &&
                            !!extraRecipients && (
                              <span style={{ paddingLeft: "4px" }}>
                                +{extraRecipients}
                              </span>
                            )}
                        </Typography>
                        <Typography variant="body2" className="accent-text">
                          {new Date(msg.time).toLocaleDateString(
                            "en-US",
                            timezoneID ? { timeZone: timezoneID } : {},
                          )}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        className="accent-text sms-mailbox-list-body"
                      >
                        {msg.body}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
            {showError && (
              <Typography
                variant="body2"
                className="error-text text-center mt-16"
              >
                We could not load your messages
              </Typography>
            )}
          </InfiniteScroll>
        </>
      ) : (
        <div className="mailbox-status-container">
          {!finishedLoading ? (
            <Loader />
          ) : showError ? (
            <>
              <img src="/images/error.svg" alt="error" />
              <Typography variant="body2" className="error-text mt-24 mb-16">
                We could not load your messages, possibly due to the high volume
                of messages.
              </Typography>
            </>
          ) : (
            <>
              <img src="/images/no_results.svg" alt="no results" />
              <Typography variant="h5" className="mt-24 mb-16">
                No messages found
              </Typography>
            </>
          )}
        </div>
      )}
    </List>
  );
};

export default memo(SmsMessageList);
