import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { convertToArray, Navigation, PageURL } from "../../../lib";
import debounce from "lodash.debounce";
import { Button, Tooltip } from "@material-ui/core";
import ConfirmationModal from "../../../components/ConfirmationModal";
import TableResultsView from "../../../components/TableResultsViewDeprecated";
import { AuthSelectors } from "../../../state";
import { JewishInterestLevels } from "../../../state/engagement/types";
import {
  classesFilterDefaults,
  classesOptions,
  engagementRoundupOptions,
  genderOptions,
  gradYearOptions,
  sortOptions,
} from "./StudentsListHelpers";

export default function StudentsListFilters(props) {
  const {
    campuses,
    chabadHouseTags = [],
    deleteTag,
    loadData,
    pageRoute: { query, page, params },
    showSearch = true,
    viewStudentsByGroup,
  } = props;

  const {
    ca = "", // campusId
    cl = "", // classes
    cldo = "", // classes default override
    er = "", // engagement roundup
    ge = "", // gender
    gr = "", // groups / CH tags
    gy = "", // grad years
    jil = "", // Jewish interest level
    se = "", // search
    so = "", // sort
  } = query;
  const clArray = convertToArray(cl);
  const gyArray = convertToArray(gy);

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );
  const showEngagementAdvancedFilters = isShliach && isEnrolledInEngagement;

  const [showDeleteGroupConfirmModal, setShowDeleteGroupConfirmModal] =
    useState(false);

  useEffect(
    () => {
      if (!showEngagementAdvancedFilters) {
        onChange("er", null);
        onChange("jil", null);
      }
    },
    // onChange is not memoized properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showEngagementAdvancedFilters],
  );

  const onChange = (name, val, debounce) => {
    const url = PageURL.to(page, params, {
      ...query,
      [name]: val
        ? typeof val === "object"
          ? val
          : encodeURIComponent(val)
        : undefined,
      ...(name === "cl" ? { cldo: true } : {}), // set cldo (classes default override) to true when classes filter is adjusted
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);

    setTimeout(debounce ? onFilterDebounce : onFilter, 0);
  };

  const onFilter = useCallback(() => {
    loadData();
  }, [loadData]);

  const onFilterDebounce = debounce(onFilter, 500);

  const chabadHouseTagsOptions = [
    { label: "All student groups", value: "" },
    ...chabadHouseTags?.map((s) => ({
      ...s,
      key: s.id,
      value: s.id,
      label: s.tagName,
    })),
  ];

  const selectedTagOption = chabadHouseTagsOptions?.find(
    (t) => t.id === parseInt(decodeURIComponent(gr)),
  );

  const campusesOptions = [
    { label: "All campuses", value: "" },
    ...(campuses
      ? campuses.map((c) => ({
          value: c.id,
          label: c.name,
        }))
      : []),
  ];

  const jewishInterestLevelsOptions = [
    { label: "All statuses", value: "" },

    ...Object.values(JewishInterestLevels).map((jil) => {
      return { label: jil, value: jil };
    }),
  ];

  const showCampusFilter = campusesOptions.length > 2;

  return (
    <>
      <ConfirmationModal
        message={`Are you sure you want to delete the group ${selectedTagOption?.label} ?`}
        show={showDeleteGroupConfirmModal}
        cancel={() => setShowDeleteGroupConfirmModal(false)}
        confirm={async () => {
          await deleteTag();
          onChange("gr", undefined);
          setShowDeleteGroupConfirmModal(false);
        }}
      />
      <div className="mobile-block full-width students-filters">
        <div className="flex">
          {showSearch && (
            <div className="search-input" style={{ marginBottom: "12px" }}>
              <input
                name="se"
                onChange={(event) =>
                  onChange(event.target.name, event.target.value, true)
                }
                placeholder="Search Student Name"
                type="text"
                value={decodeURIComponent(se)}
              />
              <i className="material-icons accent-text-secondary pointer">
                search
              </i>
            </div>
          )}

          {viewStudentsByGroup && (
            <div
              className={showSearch ? "ml-16" : "mb-8"}
              style={{ minWidth: 300 }}
            >
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: "rgba(0, 0, 0, 0.06)",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "rgba(0, 0, 0, .38)",
                    padding: "0 8px",
                    fontSize: "16px",
                  }),
                }}
                placeholder="All student groups..."
                options={chabadHouseTagsOptions || []}
                name={"gr"}
                value={selectedTagOption}
                onChange={(val, action) => {
                  if (action?.action === "clear") {
                    setShowDeleteGroupConfirmModal(true);
                    return false;
                  }
                  onChange("gr", val?.value);
                }}
                isClearable={!!gr}
                components={{ ClearIndicator: ClearIndicator }}
              />
            </div>
          )}
        </div>
        <div
          className="flex flex-align-center ml-8"
          style={{ flexFlow: "wrap" }}
        >
          <div className="student-list-filter">
            <Select
              className="student-list-filter-select"
              classNamePrefix="student-list-filter-select"
              closeMenuOnSelect={false}
              isMulti={true}
              onChange={(vals) =>
                onChange(
                  "gy",
                  vals.map((v) => v.value),
                )
              }
              options={gradYearOptions}
              placeholder="All grad years"
              value={gradYearOptions.filter((g) =>
                gyArray.includes(g.value.toString()),
              )}
            />
          </div>
          {/* Campus filter is only available for shluchim with more than one campus association */}
          {showCampusFilter && (
            <div className="student-list-filter">
              <Select
                className="student-list-filter-select"
                classNamePrefix="student-list-filter-select"
                defaultValue={campusesOptions[0]}
                isSearchable={false}
                onChange={(val) => onChange("ca", val.value)}
                options={campusesOptions}
                value={
                  campusesOptions.find(
                    (c) => c.value.toString() === decodeURIComponent(ca),
                  ) || campusesOptions[0]
                }
              />
            </div>
          )}
          <div className="student-list-filter">
            <Select
              className="student-list-filter-select"
              classNamePrefix="student-list-filter-select"
              defaultValue={genderOptions[0]}
              isSearchable={false}
              onChange={(val) => onChange("ge", val.value)}
              options={genderOptions}
              value={
                genderOptions.find(
                  (g) => g.value.toString() === decodeURIComponent(ge),
                ) || genderOptions[0]
              }
            />
          </div>
          <div className="student-list-filter">
            <Select
              className="student-list-filter-select"
              classNamePrefix="student-list-filter-select"
              closeMenuOnSelect={false}
              isMulti={true}
              onChange={(vals) =>
                onChange(
                  "cl",
                  vals.map((v) => v.value),
                )
              }
              options={classesOptions}
              placeholder="All demographics"
              value={
                cldo
                  ? classesOptions.filter((g) =>
                      clArray.includes(g.value.toString()),
                    )
                  : classesFilterDefaults
              }
            />
          </div>
          {showEngagementAdvancedFilters && (
            <>
              <div className="student-list-filter">
                <Select
                  className="student-list-filter-select"
                  classNamePrefix="student-list-filter-select"
                  defaultValue={jewishInterestLevelsOptions[0]}
                  isSearchable={false}
                  onChange={(val) => onChange("jil", val.value)}
                  options={jewishInterestLevelsOptions}
                  value={
                    jewishInterestLevelsOptions.find(
                      (j) => j.value === decodeURIComponent(jil),
                    ) || jewishInterestLevelsOptions[0]
                  }
                />
              </div>
              <div className="student-list-filter">
                <Select
                  className="student-list-filter-select"
                  classNamePrefix="student-list-filter-select"
                  defaultValue={engagementRoundupOptions[0]}
                  isSearchable={false}
                  onChange={(val) => onChange("er", val.value)}
                  options={engagementRoundupOptions}
                  value={
                    engagementRoundupOptions.find(
                      (s) => s.value === decodeURIComponent(er),
                    ) || engagementRoundupOptions[0]
                  }
                />
              </div>
            </>
          )}
          <div className="flex flex-justify-end" style={{ flex: 1 }}>
            <div className="student-list-filter">
              <p className="mr-8 nowrap-text">Sort by</p>
              <Select
                className="student-list-filter-select"
                classNamePrefix="student-list-filter-select"
                defaultValue={sortOptions[0]}
                isSearchable={false}
                onChange={(val) => onChange("so", val.value)}
                options={sortOptions}
                value={
                  sortOptions.find((s) => s.value === decodeURIComponent(so)) ||
                  sortOptions[0]
                }
              />
            </div>
            <div className="student-list-filter students-view-results">
              <TableResultsView increment={15} width={"100%"} {...props} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const CustomClearText = () => (
  <Tooltip arrow title="Delete this group">
    <Button
      className="medium-text accent-text-dark"
      style={{
        fontSize: "10px",
      }}
    >
      <i
        className={`material-icons ${"accent-text"} student-groups-heading-icons`}
        style={{ fontSize: "14px" }}
      >
        delete
      </i>{" "}
      Delete
    </Button>
  </Tooltip>
);
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,

    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};
