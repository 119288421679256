import React, { memo } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import ExportCSVButton from "../../components/ExportCSVButton";
import { AuthRequest } from "../../lib";
import { AuthSelectors } from "../../state";
import { getStudentsListQueryFilters } from "./studentsList/StudentsListHelpers";

function StudentsExportButton(props) {
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );

  const getStudents = async () => {
    const {
      pageRoute: { query },
      includeArchived,
    } = props;

    const queryFilters = getStudentsListQueryFilters(query);

    const params = {
      ...queryFilters,
      includeArchived: includeArchived,
      includeChabadHouseTags: true,
      isExport: true,
    };

    const response = await AuthRequest.get(
      `Students/byShliach?${queryString.stringify(params)}`,
    );

    return response.data.payload.results;
  };

  const getHeaders = () => {
    const headers = [
      { label: "COCI Student ID", value: "studentID" },
      { label: "First Name", value: "studentFirstName" },
      { label: "Last Name", value: "studentLastName" },
      { label: "Email", value: "email" },
      { label: "Phone", value: "cell" },
      { label: "Gender", value: "gender" },
      {
        label: "Dob",
        value: ({ dob, isDobEstimated }) =>
          dob && !isDobEstimated ? moment(dob).format("MM/DD/YYYY") : "",
      },
      { label: "Age", value: "age" },
      {
        label: "Student Identifies as Jewish",
        value: ({ isJewish }) =>
          isJewish ? "Yes" : isJewish === false ? "No" : "",
      },
      {
        label: "Date Created",
        value: ({ dateCreated }) =>
          dateCreated ? moment(dateCreated).format("MM/DD/YYYY") : "",
      },
      { label: "Hebrew name", value: "hebrewName" },
      { label: "Campus", value: "campusName" },
      { label: "School Student ID", value: "studentIDNumber" },
      {
        label: "Demographic",
        value: ({ classDisplay }) =>
          classDisplay !== "Unknown" ? classDisplay : "",
      },
      { label: "Graduation Year", value: "graduationYear" },
      { label: "Major", value: "major" },
      { label: "Double Major", value: "doubleMajor" },
      { label: "Shirt Size", value: "tShirtSizeDisplay" },
      {
        label: "Consented to Marketing Contact",
        value: ({ didConsentToMarketingContact }) =>
          didConsentToMarketingContact
            ? "Yes"
            : didConsentToMarketingContact === false
            ? "No"
            : "",
      },
      {
        label: "Consented to Usage of Quotes for Marketing",
        value: ({ didConsentToMarketingQuotes }) =>
          didConsentToMarketingQuotes
            ? "Yes"
            : didConsentToMarketingQuotes === false
            ? "No"
            : "",
      },
      { label: "Address 1", value: "address1" },
      { label: "Address 2", value: "address2" },
      { label: "City", value: "city" },
      { label: "State", value: "state" },
      { label: "Country", value: "country" },
      { label: "Zip", value: "zip" },
      { label: "Father's Background", value: "fatherBackgroundDisplay" },
      { label: "Mother's Background", value: "motherBackgroundDisplay" },
      { label: "Father's Name", value: "fatherName" },
      { label: "Father's Email", value: "fatherEmail" },
      { label: "Father's Phone", value: "fatherPhone" },
      { label: "Mother's Name", value: "motherName" },
      { label: "Mother's Email", value: "motherEmail" },
      { label: "Mother's Phone", value: "motherPhone" },
      {
        label: "Parents Reside at Same Address",
        value: ({ doParentsShareResidence }) =>
          doParentsShareResidence
            ? "Yes"
            : doParentsShareResidence === false
            ? "No"
            : "",
      },
      { label: "Hebrew Level", value: "hebrewLevelDisplay" },
      {
        label: "Had Bar/Bat Mitzvah",
        value: ({ hadBarBatMitzvah }) =>
          hadBarBatMitzvah ? "Yes" : hadBarBatMitzvah === false ? "No" : "",
      },
      {
        label: "Jewish Israel Knowledge Level",
        value: "jewishIsraelKnowledgeLevelDisplay",
      },
      {
        label: "Has Taken Israel Courses",
        value: ({ hasTakenIsraelCourses }) =>
          hasTakenIsraelCourses
            ? "Yes"
            : hasTakenIsraelCourses === false
            ? "No"
            : "",
      },
      {
        label: "Jewish Education",
        value: ({ jewishEducations, hasNoJewishEducation }) =>
          jewishEducations
            ? jewishEducations
                .map(
                  (je) =>
                    `${je.typeDisplay}: ${je.name}${
                      je.affiliationDisplay ? ` (${je.affiliationDisplay})` : ""
                    }`,
                )
                .join(", ")
            : hasNoJewishEducation
            ? "None"
            : "",
      },
      {
        label: "Allergies and Dietary Restrictions",
        value: ({ allergiesDisplay }) =>
          allergiesDisplay ? allergiesDisplay.join(", ") : "",
      },
      {
        label: "Additional Dietary Information and/or Severity of Allergies",
        value: "dietaryNotes",
      },
      {
        label: "Groups",
        value: ({ allTagNames }) => (allTagNames || []).join(", "),
      },
    ];

    if (isShliach && isEnrolledInEngagement) {
      headers.push(
        {
          label: "Current Jewish Interest Level",
          value: "currentJil",
        },
        {
          label: "Tagged For Current Month",
          value: ({ isTaggedForCurrentMonth }) =>
            isTaggedForCurrentMonth ? "Yes" : "",
        },
      );
    }

    return headers;
  };

  return (
    <ExportCSVButton
      disabled={props.disabled}
      className="btn btn-secondary students-export-btn"
      fileName="Students"
      getExportData={getStudents}
      getHeaders={getHeaders}
    />
  );
}

export default memo(StudentsExportButton);
